import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from '../src/locales/en/en_common.json';
import enEwallet from '../src/locales/en/en_ewallet.json';
import enDashboard from '../src/locales/en/en_dashboard.json'
import enProfile from '../src/locales/en/en_profile.json'
import enPayout from '../src/locales/en/en_payout.json'
import enEpin from '../src/locales/en/en_epin.json'
import enRegister from '../src/locales/en/en_register.json'
import enTree from '../src/locales/en/en_tree.json'
import enError from '../src/locales/en/en_error.json'
import enShopping from '../src/locales/en/en_shopping.json'
import enReplica from '../src/locales/en/en_replica.json'
import enMailbox from '../src/locales/en/en_mailBox.json'
import enLeads from '../src/locales/en/en_leads.json'
import enSubscription from '../src/locales/en/en_subscription.json'
import enTicket from '../src/locales/en/en_tickets.json'
import idCommon from '../src/locales/id/id_common.json';
import idEwallet from '../src/locales/id/id_ewallet.json';
import idDashboard from '../src/locales/id/id_dashboard.json'
import idProfile from '../src/locales/id/id_profile.json'
import idPayout from '../src/locales/id/id_payout.json'
import idEpin from '../src/locales/id/id_epin.json'
import idRegister from '../src/locales/id/id_register.json'
import idTree from '../src/locales/id/id_tree.json'
import idError from '../src/locales/id/id_error.json'
import idShopping from '../src/locales/id/id_shopping.json'
import idReplica from '../src/locales/id/id_replica.json'
import idMailbox from '../src/locales/id/id_mailBox.json'
import idLeads from '../src/locales/id/id_leads.json'
import idSubscription from '../src/locales/id/id_subscription.json'
import idTicket from '../src/locales/id/id_tickets.json'


const savedLanguage = JSON.parse(localStorage.getItem('userLanguage'));

const mergedTranslations = {
  en: {
    translation: { ...enCommon, ...enEwallet, ...enDashboard, ...enPayout, ...enEpin, ...enProfile, ...enRegister, ...enTree, ...enError, ...enShopping, ...enReplica, ...enLeads, ...enMailbox, ...enSubscription, ...enTicket },
  },
  id: {
    translation: { ...idCommon, ...idEwallet, ...idDashboard, ...idPayout, ...idEpin, ...idProfile, ...idRegister, ...idTree, ...idError, ...idShopping, ...idReplica, ...idLeads, ...idMailbox, ...idSubscription, ...idTicket },
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources: mergedTranslations,
    lng: (savedLanguage?.code) ? savedLanguage?.code : 'id',
    interpolation: {
      escapeValue: false,
    },
  });


export default i18n;
