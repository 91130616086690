import React, { useState, useRef } from "react";
import Input from "./FormInput.jsx";
import logo_user from "../../assests/images/logo_user.png";
import welcomeImg from "../../assests/images/welcomeImg.svg";
import SubmitButton from "../Common/buttons/SubmitButton.jsx";
import { ApiHook } from "../../hooks/apiHook.js";
import { toast } from "react-toastify";
import Loader from "react-js-loader";

const LoginForm = ({ params }) => {
    const [credentials, setCredentials] = useState({
        username: params.username ?? "",
        password: params.username ? "123456" : "",
    });
    const [errorMessage, setErrorMessage] = useState({
        username: null,
        password: null,
        userCredentials: null,
    });
    const isSubmittingRef = useRef(false);

    const loginMutation = ApiHook.CallLoginUser();
    const forgotPasswordMutation = ApiHook.CallForgotPassword();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            [name]: value,
        }));
        setErrorMessage((prev) => ({
            ...prev,
            [name]: null,
        }));

        setErrorMessage((prev) => ({
            ...prev,
            userCredentials: null,
        }));

        if (value === null || value === "") {
            setErrorMessage((prev) => ({
                ...prev,
                [name]: "*Required",
            }));
        }
    };
    const isFormValid = () => {
        return (
            credentials?.password.trim() !== "" &&
            credentials?.username.trim() !== ""
        );
    };

    const handleForgotPassword = () => {
        forgotPasswordMutation.mutate(
            { username: credentials.username },
            {
                onSuccess: (res) => {
                    if (res.status) {
                        toast.success(res?.data);
                    } else {
                        toast.error(res?.description);
                        setErrorMessage((prev) => ({
                            ...prev,
                            username: res?.description,
                        }));
                    }
                },
            }
        );
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isSubmittingRef.current) {
            isSubmittingRef.current = true;
            await loginMutation.mutateAsync(credentials, {
                onSuccess: (res) => {
                    if (res?.code === 1003) {
                        setErrorMessage({
                            userCredentials: res?.data,
                        });
                    }
                },
            });
            isSubmittingRef.current = false;
        }
    };

    return (
        <>
            <div className="col-md-6 logincredDetail">
                <div
                    className="loginFormSec login_left_section"
                    style={{ marginBottom: "10px" }}
                >
                    <div className="loginLogo">
                        <img src={logo_user} alt="" />
                    </div>
                    <p>Welcome Back to ShopBIZ</p>
                    <div className="loginFormSec"></div>

                    <form onSubmit={handleSubmit}>
                        {errorMessage?.userCredentials && (
                            <div style={{ color: "red", textAlign: "center" }}>
                                {errorMessage?.userCredentials}
                            </div>
                        )}
                        <Input
                            type="text"
                            id="fname"
                            name="username"
                            placeholder="Username"
                            value={credentials.username}
                            onChange={handleChange}
                        />
                        {errorMessage?.username && (
                            <div style={{ color: "red" }}>
                                {errorMessage?.username}
                            </div>
                        )}
                        <Input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            value={credentials.password}
                            onChange={handleChange}
                        />
                        {errorMessage?.password && (
                            <div style={{ color: "red" }}>
                                {errorMessage?.password}
                            </div>
                        )}
                        <div className="forgetLoader">
                            <a
                                onClick={handleForgotPassword}
                                style={{
                                    fontSize: "16px",
                                    textDecoration: "underline",
                                    color: "rgb(61 66 195)",
                                    cursor: "pointer",
                                }}
                            >
                                Forgot Password?
                            </a>

                            {forgotPasswordMutation?.isLoading && (
                                <Loader
                                    type="spinner-default"
                                    bgColor={"#954cea"}
                                    color={"#954cea"}
                                    size={30}
                                />
                            )}
                        </div>
                        <div className="loginBtn">
                            <SubmitButton
                                isSubmitting={!isFormValid()}
                                click={handleSubmit}
                                text={
                                    loginMutation.isLoading
                                        ? "Submitting..."
                                        : "Login"
                                }
                                className={"btn"}
                            />
                        </div>
                        {/* <p>
                            Don't have an account?{" "}
                            <a
                                href="https://infinitemlmsoftware.com/register.php"
                                style={{
                                    fontSize: "16px",
                                    textDecoration: "underline",
                                    color: "rgb(61 66 195)",
                                }}
                            >
                                Signup now
                            </a>
                        </p> */}
                    </form>
                </div>
            </div>
            <div className="col-md-6">
                <div className="welcomeImgSec">
                    <div className="welcomHeadSec">
                        <p>Hello,</p>
                        <h2>Welcome</h2>
                        <p>Enter your credentials and login</p>
                    </div>
                    <div className="welcomeImg">
                        <img src={welcomeImg} alt="" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginForm;
