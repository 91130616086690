export const reverseNumberDisplay = (str) => {
    if (str === "NaN") return NaN;
    if (str === "0") return 0;
    const lastChar = str.charAt(str.length - 1);
    const numericValue = parseFloat(str);

    if (lastChar === "rb") return numericValue * 1e3;
    if (lastChar === "jt") return numericValue * 1e6;
    if (lastChar === "M") return numericValue * 1e9;

    return numericValue;
};

export const numberDisplay = (num) => {
    if (isNaN(num)) return "NaN";
    if (num === 0) return "0";

    let number = Math.abs(num);
    if (number < 1e3) return number.toFixed(2);
    if (number < 1e6) return (number / 1e3).toFixed(2) + "rb";
    if (number < 1e9) return (number / 1e6).toFixed(2) + "jt";
    if (number >= 1e9) return (number / 1e9).toFixed(2) + "M";
};

export const decimalDisplay = (num) => {
    if (isNaN(num)) return "0";
    if (num === 0) return "0";

    let number = Math.abs(num);
    return new Intl.NumberFormat("id-ID", {
        style: "decimal",
        maximumSignificantDigits: 3,
    }).format(number);
};

export const currencyConvertBoth = () => {

}
